<template>
  <p class="page-container">
    <span>404 PAGE</span>
    <br/>
    <br/>
    <span>NOT FOUND</span>
  </p>
</template>

<script>
  export default {
    name: 'index'
  }
</script>

<style scoped>
  .page-container {
    font-size: 20px;
    text-align: center;
    margin-top: 15%;
    color: rgb(83, 86, 88);
  }
</style>
